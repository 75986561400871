/**
 * Profile Service
 * Handles all interactions with profiles stored in Supabase
 */

import { supabase } from './supabaseClient';

// Define Profile interface
export interface Profile {
  id?: string;
  full_name: string;
  email: string;
  birth_date: string;
  birth_time?: string | null;
  birth_city: string;
  birth_country: string;
  moon_sign?: string;
  moon_phase?: string | null;
  // Astrological positions
  ascendant?: string | null;
  ascendant_house?: string | null;
  ascendant_sign?: string | null;
  mercury_in?: string | null;
  mercury_in_house?: string | null;
  venus_in?: string | null;
  venus_in_house?: string | null;
  mars_in?: string | null;
  mars_in_house?: string | null;
  jupiter_in?: string | null;
  jupiter_in_house?: string | null;
  saturn_in?: string | null;
  saturn_in_house?: string | null;
  uranus_in?: string | null;
  uranus_in_house?: string | null;
  neptune_in?: string | null;
  neptune_in_house?: string | null;
  pluto_in?: string | null;
  pluto_in_house?: string | null;
  // Interpretation fields
  element?: string | null;
  mode?: string | null;
  purpose?: string | null;
  gifts?: string[] | null;
  // System fields
  email_sent?: boolean;
  subscription_level?: string;
  created_at?: string;
  access_token?: string;
}

// Define visitor tracking interface
export interface PageVisit {
  profile_id: string;
  page_url: string;
  user_agent?: string;
  ip_address?: string;
}

/**
 * Gets a profile by its access token
 * @param token The access token
 * @returns The profile or null
 */
export const getProfileByToken = async (token: string): Promise<Profile | null> => {
  try {
    // Call the Netlify function to get the profile
    const response = await fetch(`/.netlify/functions/get-profile-by-token?token=${token}`);
    
    if (!response.ok) {
      throw new Error(`Failed to fetch profile: ${response.status}`);
    }
    
    const data = await response.json();
    
    if (data.profile) {
      return data.profile;
    }
    
    return null;
  } catch (error) {
    console.error('Error fetching profile:', error);
    return null;
  }
};

/**
 * Save user profile and lunar data to Supabase
 * @param profileData The profile data to save
 * @returns The result of the operation
 */
export const saveProfile = async (profileData: any): Promise<{
  success: boolean;
  profile?: Profile;
  accessToken?: string;
  message?: string;
  error?: any;
}> => {
  try {
    // Call the Netlify function to save the profile
    const response = await fetch('/.netlify/functions/save-profile', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(profileData),
    });

    if (!response.ok) {
      throw new Error(`Failed to save profile: ${response.status}`);
    }

    const result = await response.json();
    
    if (result.success) {
      return {
        success: true,
        accessToken: result.accessToken,
        message: result.message
      };
    } else {
      return {
        success: false,
        error: result.error || 'Unknown error occurred'
      };
    }
  } catch (error) {
    console.error('Error saving profile:', error);
    return {
      success: false,
      error: error instanceof Error ? error.message : 'Unknown error occurred'
    };
  }
};

/**
 * Track a page visit
 * @param visit The visit data to record
 * @returns Success indicator
 */
export const trackPageVisit = async (visit: PageVisit): Promise<boolean> => {
  try {
    const { error } = await supabase
      .from('page_visits')
      .insert(visit);
    
    if (error) {
      console.error('Error tracking page visit:', error);
      return false;
    }
    
    return true;
  } catch (error) {
    console.error('Error tracking page visit:', error);
    return false;
  }
};

/**
 * Handle user upgrade to premium subscription
 * @param profileId The profile ID
 * @returns Success indicator
 */
export const trackUpgradeClick = async (profileId: string): Promise<boolean> => {
  try {
    const { error } = await supabase
      .from('page_visits')
      .insert({
        profile_id: profileId,
        page_url: '/free-moon-reading/upgrade-click',
        user_agent: navigator.userAgent
      });
    
    if (error) {
      console.error('Error tracking upgrade click:', error);
      return false;
    }
    
    return true;
  } catch (error) {
    console.error('Error tracking upgrade click:', error);
    return false;
  }
};

export default {
  getProfileByToken,
  saveProfile,
  trackPageVisit,
  trackUpgradeClick
};
