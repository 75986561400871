/**
 * Supabase Client Utility
 * 
 * This file provides a centralized Supabase client instance for use throughout the application.
 * It ensures we're using environment variables correctly and only initializing once.
 */

import { createClient } from '@supabase/supabase-js';

// Get environment variables - using Create React App environment variable naming
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_KEY || process.env.REACT_APP_SUPABASE_ANON_KEY;

// Validate environment variables
if (!supabaseUrl || !supabaseAnonKey) {
  console.error(
    'Missing Supabase environment variables. Please check your .env file or Netlify environment variables.'
  );
}

// Create a single supabase client for the entire app
export const supabase = createClient(
  supabaseUrl || '',
  supabaseAnonKey || ''
);

/**
 * Example usage:
 * 
 * import { supabase } from '../utils/supabaseClient';
 * 
 * // Authentication
 * const signIn = async (email, password) => {
 *   const { data, error } = await supabase.auth.signInWithPassword({
 *     email,
 *     password,
 *   });
 *   return { data, error };
 * };
 * 
 * // Database operations
 * const fetchUserProfile = async (userId) => {
 *   const { data, error } = await supabase
 *     .from('profiles')
 *     .select('*')
 *     .eq('id', userId)
 *     .single();
 *   return { data, error };
 * };
 */
