/**
 * SendGrid Email Client Configuration
 * 
 * Provides a configured SendGrid client instance using the API key
 * stored in the .env file.
 */

import sgMail from '@sendgrid/mail';
import { sendgrid as sendgridConfig } from './env';

// Set the SendGrid API key from environment variables
if (sendgridConfig.apiKey) {
  sgMail.setApiKey(sendgridConfig.apiKey);
} else {
  console.error(
    'Missing SendGrid API key. Make sure REACT_APP_SENDGRID_API_KEY is set in .env file.'
  );
}

/**
 * Send an email using SendGrid
 * 
 * @param {Object} options - Email options
 * @param {string} options.to - Recipient email address
 * @param {string} options.from - Sender email address (must be verified with SendGrid)
 * @param {string} options.subject - Email subject
 * @param {string} options.text - Plain text content
 * @param {string} options.html - HTML content (optional)
 * @returns {Promise} - SendGrid API response
 */
export const sendEmail = async ({
  to,
  from,
  subject,
  text,
  html,
}: {
  to: string;
  from: string;
  subject: string;
  text: string;
  html?: string;
}) => {
  try {
    // Check if SendGrid is configured
    if (!sendgridConfig.isConfigured()) {
      throw new Error('SendGrid is not configured. Cannot send email.');
    }

    // Send the email
    const response = await sgMail.send({
      to,
      from,
      subject,
      text,
      html: html || text, // Use HTML if provided, otherwise use text
    });

    return {
      success: true,
      response,
    };
  } catch (error) {
    console.error('Error sending email:', error);
    return {
      success: false,
      error,
    };
  }
};

export default {
  sendEmail,
};

/**
 * Example usage:
 * 
 * import { sendEmail } from './utils/sendgridClient';
 * 
 * // Send an email
 * const result = await sendEmail({
 *   to: 'recipient@example.com',
 *   from: 'your-verified-sender@example.com', // Must be verified in SendGrid
 *   subject: 'Hello from Moon Manifest',
 *   text: 'This is a test email from Moon Manifest.',
 *   html: '<p>This is a <strong>test email</strong> from Moon Manifest.</p>', // Optional
 * });
 * 
 * if (result.success) {
 *   console.log('Email sent successfully');
 * } else {
 *   console.error('Failed to send email:', result.error);
 * }
 */
