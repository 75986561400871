import React from 'react';
import { Helmet } from 'react-helmet-async';
import Navbar from './Navbar';

const Header: React.FC = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#1A2359" />
        <meta 
          name="description" 
          content="Unlock your true potential with MoonManifest - the app that combines lunar cycles with manifestation techniques. Get your free moon reading now and start manifesting your dreams today!"
        />
        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/logo192.png" />
        <link rel="manifest" href="/manifest.json" />
        
        {/* Google Analytics would go here */}
        
        {/* Facebook Pixel Code would go here */}
        
        <title>MoonManifest | Harness Lunar Power to Manifest Your Dreams</title>
      </Helmet>
      <header>
        <Navbar />
      </header>
    </>
  );
};

export default Header;