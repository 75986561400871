/**
 * Environment Variables Utility
 * Provides structured access to environment variables and API keys
 */

/**
 * Supabase configuration
 */
export const supabase = {
  url: process.env.REACT_APP_SUPABASE_URL || '',
  key: process.env.REACT_APP_SUPABASE_KEY || '',
  
  /**
   * Checks if Supabase is properly configured
   */
  isConfigured: (): boolean => {
    return Boolean(process.env.REACT_APP_SUPABASE_URL && process.env.REACT_APP_SUPABASE_KEY);
  }
};

/**
 * SendGrid configuration
 */
export const sendgrid = {
  apiKey: process.env.REACT_APP_SENDGRID_API_KEY || '',
  
  /**
   * Checks if SendGrid is properly configured
   */
  isConfigured: (): boolean => {
    return Boolean(process.env.REACT_APP_SENDGRID_API_KEY);
  }
};

/**
 * Logger utility for environment variable issues
 */
export const checkEnvironmentVariables = (): void => {
  // Check Supabase configuration
  if (!supabase.isConfigured()) {
    console.warn('Supabase environment variables are missing or incomplete.');
    
    if (!process.env.REACT_APP_SUPABASE_URL) {
      console.warn('Missing REACT_APP_SUPABASE_URL');
    }
    
    if (!process.env.REACT_APP_SUPABASE_KEY) {
      console.warn('Missing REACT_APP_SUPABASE_KEY');
    }
  }

  // Check SendGrid configuration
  if (!sendgrid.isConfigured()) {
    console.warn('SendGrid API key is missing. Email functionality may not work properly.');
  }
};

// Export environment check function as default
export default checkEnvironmentVariables;
