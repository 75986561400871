import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import './index.css';
import App from './App';
import { initializeDebugTools, debugLog, startHangDetection, logMemoryUsage } from './utils/debugUtils';
import { checkEnvironmentVariables } from './utils';

console.log('🚀 Application initializing...');

// Check environment variables for API configurations
checkEnvironmentVariables();

// Store application start time
const APP_START_TIME = performance.now();

// Track webpack compilation stages for hang detection
(window as any).__webpackCompilationStages = [];

// Initialize debugging tools in development mode
if (process.env.NODE_ENV === 'development') {
  console.log('🔍 Starting debug instrumentation...');
  
  // Initialize our debug tools
  initializeDebugTools();
  
  // Add a global variable to help diagnose issues from the console
  (window as any).__appInfo = {
    startTime: APP_START_TIME,
    reactVersion: React.version,
    lastRenderTime: null,
    moduleLoadErrors: [],
    moduleLoadTimes: {},
    resourceLoadErrors: []
  };
  
  // Track slow modules
  const originalImport = (window as any).import;
  if (originalImport) {
    (window as any).import = function(moduleUrl: string) {
      const moduleLoadStart = performance.now();
      debugLog(`Loading module: ${moduleUrl}`, 'info');
      
      return originalImport(moduleUrl)
        .then((module: any) => {
          const loadTime = performance.now() - moduleLoadStart;
          (window as any).__appInfo.moduleLoadTimes[moduleUrl] = loadTime;
          
          if (loadTime > 300) {
            debugLog(`Slow module load: ${moduleUrl} took ${loadTime.toFixed(2)}ms`, 'warn');
          }
          
          return module;
        })
        .catch((error: Error) => {
          debugLog(`Module load error: ${moduleUrl}`, 'error', error);
          (window as any).__appInfo.moduleLoadErrors.push({ 
            module: moduleUrl, 
            error: error.message, 
            time: new Date().toISOString() 
          });
          throw error;
        });
    };
  }
  
  // Track resource loading errors
  window.addEventListener('error', (event: ErrorEvent) => {
    // Check if this is a resource loading error
    const target = event.target as HTMLElement;
    if (target && (target.tagName === 'SCRIPT' || target.tagName === 'LINK' || target.tagName === 'IMG')) {
      let resource = '';
      
      if (target.tagName === 'LINK') {
        resource = (target as HTMLLinkElement).href;
      } else if (target.tagName === 'IMG' || target.tagName === 'SCRIPT') {
        resource = (target as HTMLImageElement | HTMLScriptElement).src;
      }
      
      debugLog(`Resource loading error: ${resource}`, 'error');
      
      (window as any).__appInfo.resourceLoadErrors.push({
        resource,
        type: target.tagName.toLowerCase(),
        time: new Date().toISOString()
      });
    }
  }, true); // Use capture phase to catch resource errors
  
  // Start hang detection for operations that might freeze the UI
  startHangDetection(10000); // 10 seconds threshold
  
  // Watch for UI hangs and long tasks by monitoring frame rate
  let lastFrameTime = performance.now();
  let hangDetected = false;
  
  requestAnimationFrame(function checkFrameRate() {
    const now = performance.now();
    const frameDuration = now - lastFrameTime;
    
    // If frame took longer than 200ms, might indicate a UI hang
    if (frameDuration > 200 && !hangDetected) {
      debugLog(
        `Potential UI hang detected: Frame took ${frameDuration.toFixed(2)}ms`, 
        'warn'
      );
      
      // Log memory usage when a hang is detected
      logMemoryUsage();
      hangDetected = true;
      
      // Reset hang detected flag after a while
      setTimeout(() => {
        hangDetected = false;
      }, 5000);
    }
    
    lastFrameTime = now;
    requestAnimationFrame(checkFrameRate);
  });
  
  // Add a simple command to check app status from console
  (window as any).checkAppStatus = () => {
    const now = performance.now();
    const uptime = now - APP_START_TIME;
    
    console.log(`
    📊 App Status:
    - Uptime: ${(uptime / 1000).toFixed(2)}s
    - React Version: ${React.version}
    - Module Load Errors: ${(window as any).__appInfo.moduleLoadErrors.length}
    - Resource Load Errors: ${(window as any).__appInfo.resourceLoadErrors.length}
    - Environment: ${process.env.NODE_ENV}
    `);
    
    // Log current memory usage
    logMemoryUsage();
  };
  
  console.log('🐞 Development mode with advanced debug monitoring enabled');
  console.log('💡 Type "checkAppStatus()" in the console for app diagnostics');
}

// Create root and render application
const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');

const root = ReactDOM.createRoot(container);

// We're specifically NOT using StrictMode here since we suspect it might be
// contributing to rendering issues in development. This can be re-enabled later.
root.render(
  <BrowserRouter>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </BrowserRouter>
);
