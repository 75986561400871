import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import './App.css';

// Use React.lazy for code splitting
const Home = lazy(() => import('./pages/Home'));
const Manifestation = lazy(() => import('./pages/Manifestation'));
const LawOfAttraction = lazy(() => import('./pages/LawOfAttraction'));
const MoonPhases = lazy(() => import('./pages/MoonPhases'));
const MoonSigns = lazy(() => import('./pages/MoonSigns'));
const Blog = lazy(() => import('./pages/Blog'));
const FreeMoonReading = lazy(() => import('./pages/FreeMoonReading'));
const AppPage = lazy(() => import('./pages/AppPage'));

// Moon Phases
const NewMoon = lazy(() => import('./pages/moon-phases/NewMoon'));
const FullMoon = lazy(() => import('./pages/moon-phases/FullMoon'));
const FirstQuarter = lazy(() => import('./pages/moon-phases/FirstQuarter'));
const LastQuarter = lazy(() => import('./pages/moon-phases/LastQuarter'));
const WaxingCrescent = lazy(() => import('./pages/moon-phases/WaxingCrescent'));
const WaxingGibbous = lazy(() => import('./pages/moon-phases/WaxingGibbous'));
const WaningCrescent = lazy(() => import('./pages/moon-phases/WaningCrescent'));
const WaningGibbous = lazy(() => import('./pages/moon-phases/WaningGibbous'));

// Moon Signs
const AriesMoon = lazy(() => import('./pages/moon-signs/AriesMoon'));
const TaurusMoon = lazy(() => import('./pages/moon-signs/TaurusMoon'));
const GeminiMoon = lazy(() => import('./pages/moon-signs/GeminiMoon'));
const CancerMoon = lazy(() => import('./pages/moon-signs/CancerMoon'));
const LeoMoon = lazy(() => import('./pages/moon-signs/LeoMoon'));

function App() {
  return (
    <div className="App min-h-screen bg-gradient-to-br from-celestial-navy to-cosmic-purple text-white">
      <Suspense fallback={
        <div className="flex justify-center items-center min-h-screen">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-warm-gold"></div>
        </div>
      }>
        <Routes>
          <Route path="/" element={
            <>
              <Header />
              <Home />
              <Footer />
            </>
          } />

          {/* Main pages */}
          <Route path="/manifestation" element={
            <>
              <Header />
              <Manifestation />
              <Footer />
            </>
          } />

          <Route path="/law-of-attraction" element={
            <>
              <Header />
              <LawOfAttraction />
              <Footer />
            </>
          } />

          <Route path="/moon-phases" element={
            <>
              <Header />
              <MoonPhases />
              <Footer />
            </>
          } />

          <Route path="/blog" element={
            <>
              <Header />
              <Blog />
              <Footer />
            </>
          } />
          
          <Route path="/app" element={
            <>
              <Header />
              <AppPage />
              <Footer />
            </>
          } />

          <Route path="/free-moon-reading" element={
            <>
              <Header />
              <FreeMoonReading />
              <Footer />
            </>
          } />
          
          <Route path="/moon-signs" element={
            <>
              <Header />
              <MoonSigns />
              <Footer />
            </>
          } />

          {/* Moon phases sub-pages */}
          <Route path="/moon-phases/new-moon" element={
            <>
              <Header />
              <NewMoon />
              <Footer />
            </>
          } />
          <Route path="/moon-phases/full-moon" element={
            <>
              <Header />
              <FullMoon />
              <Footer />
            </>
          } />
          <Route path="/moon-phases/first-quarter" element={
            <>
              <Header />
              <FirstQuarter />
              <Footer />
            </>
          } />
          <Route path="/moon-phases/last-quarter" element={
            <>
              <Header />
              <LastQuarter />
              <Footer />
            </>
          } />
          <Route path="/moon-phases/waxing-crescent" element={
            <>
              <Header />
              <WaxingCrescent />
              <Footer />
            </>
          } />
          <Route path="/moon-phases/waxing-gibbous" element={
            <>
              <Header />
              <WaxingGibbous />
              <Footer />
            </>
          } />
          <Route path="/moon-phases/waning-crescent" element={
            <>
              <Header />
              <WaningCrescent />
              <Footer />
            </>
          } />
          <Route path="/moon-phases/waning-gibbous" element={
            <>
              <Header />
              <WaningGibbous />
              <Footer />
            </>
          } />

          {/* Moon signs sub-pages */}
          <Route path="/moon-signs/aries" element={
            <>
              <Header />
              <AriesMoon />
              <Footer />
            </>
          } />
          <Route path="/moon-signs/taurus" element={
            <>
              <Header />
              <TaurusMoon />
              <Footer />
            </>
          } />
          <Route path="/moon-signs/gemini" element={
            <>
              <Header />
              <GeminiMoon />
              <Footer />
            </>
          } />
          <Route path="/moon-signs/cancer" element={
            <>
              <Header />
              <CancerMoon />
              <Footer />
            </>
          } />
          <Route path="/moon-signs/leo" element={
            <>
              <Header />
              <LeoMoon />
              <Footer />
            </>
          } />

          {/* 404 route */}
          <Route path="*" element={
            <>
              <Header />
              <div className="container mx-auto px-4 py-20 text-center">
                <h1 className="text-4xl font-bold mb-6">Page Not Found</h1>
                <p className="mb-8">The page you're looking for doesn't exist or has been moved.</p>
                <a href="/" className="bg-warm-gold hover:bg-opacity-90 text-white font-bold py-3 px-6 rounded-full">
                  Return to Home
                </a>
              </div>
              <Footer />
            </>
          } />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
