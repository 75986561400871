import React from 'react';
import { Link } from 'react-router-dom';

const Navbar: React.FC = () => {
  return (
    <nav className="bg-celestial-navy py-4 px-6 flex flex-col md:flex-row justify-between items-center">
      <div className="flex items-center mb-4 md:mb-0">
        <Link to="/" className="flex items-center">
          <div className="w-10 h-10 rounded-full bg-white flex items-center justify-center">
            <div className="w-8 h-8 rounded-full bg-celestial-navy"></div>
          </div>
          <span className="ml-2 text-xl font-bold">MoonManifest</span>
        </Link>
      </div>
      <div className="flex flex-wrap justify-center space-x-3 md:space-x-6">
        <Link to="/" className="hover:text-warm-gold py-2">Home</Link>
        <Link to="/free-moon-reading" className="hover:text-warm-gold py-2">Free Moon Reading</Link>
        <Link to="/app" className="hover:text-warm-gold py-2">Try App</Link>
        <Link to="/moon-signs" className="hover:text-warm-gold py-2">Moon Signs</Link>
        <Link to="/moon-phases" className="hover:text-warm-gold py-2">Moon Phases</Link>
        <Link to="/manifestation" className="hover:text-warm-gold py-2">Manifestation</Link>
        <Link to="/blog" className="hover:text-warm-gold py-2">Blog</Link>
      </div>
    </nav>
  );
};

export default Navbar;